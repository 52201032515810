* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: 0 0;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    outline: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    background: 0 0;
    font-size: 15px;
}

input[type="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

ol,
ul {
    list-style: none;
}

blockquote {
    quotes: none;
}

blockquote:after,
blockquote:before {
    content: "";
    content: none;
}

del {
    text-decoration: line-through;
}

address,
article,
aside,
footer,
header,
nav,
section {
    display: block;
}

body {
    font-family: "DM_sans";
    font-size: 14px;
    overflow-x: scroll;
    min-width: 1080px;
}

body::-webkit-scrollbar {
    display: none;
}

input,
select,
textarea {
    font-family: "DM_sans";
}

html {
    overflow: scroll !important;
    scroll-behavior: smooth !important;
}

html.modal-enabled {
    overflow: hidden !important;
}

a {
    color: #444;
    cursor: pointer;
}

.submit:hover,
/* a:hover {
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
} */

.thumb {
    display: block;
    cursor: default;
}

/* body {
    font-family: "DM_sans";
    font-size: 17px;
    overflow-x: hidden;
    min-width: 320px;
    cursor: default;
} */

body::-webkit-scrollbar {
    display: none;
}

input,
select,
textarea {
    font-family: "DM_sans";
}

html {
    overflow: scroll !important;
    scroll-behavior: smooth !important;
}

html.modal-enabled {
    overflow: hidden !important;
}

.submit:hover,
/* a:hover {
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
} */

.thumb {
    display: block;
}

.thumb img {
    display: block;
    width: 100%;
}

p {
    line-height: 1.6em;
    color: #868686;
    font-size: 14px;
    color: #818181;
    font-family: "DM_sans";
}

.hidden {
    display: none;
}

br.clear {
    clear: both;
    line-height: 0;
}

.bold {
    font-family: "DM_sans_medium";
}

.e-t-h {
    margin-bottom: 25px;
}

.e-t-h2 {
    font-size: 37px;
    color: #444;
    font-family: "DM_sans_medium";
    letter-spacing: 0.02rem;
}

.b-regular {
    font-family: "DM_sans_bold";
}

.b-medium {
    font-family: "DM_sans_medium";
}

.b-semi {
    font-family: "DM_sans";
}

.white {
    color: #fff;
}

.black h5 {
    color: #000;
    font-size: 18px;
}

a,
input {
    font-family: "DM_Sans";
    color: #000;
    font-weight: 400;
    font-size: 16px;
}

input::-webkit-input-placeholder {
    color: #9b9a9a;
}

input:-moz-placeholder {
    color: #9b9a9a;
}
h1 {
    font-family: "DM_Sans_bold";
    color: #000;
    /* font-weight: 700; */
}
h2 {
    font-size: 20px;
    margin: 0;
    font-family: "DM_sans_medium";
    color: #000;
    /* font-weight: 700; */
}

@font-face {
    font-display: auto;
    font-family: "DM_sans";
    src: url(../fonts/DMSans-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: "DM_sans_medium";
    src: url(../fonts/DMSans-Medium.woff) format("woff");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: "DM_sans_bold";
    src: url(../fonts/DMSans-Bold.woff) format("woff");
    font-weight: 700;
    font-style: normal;
}
.wrapper {
    width: 85%;
    margin: 0 auto;
}

.inner-wrapper {
    width: 70%;
    margin: 0 auto;
}

@media all and (max-width: 1280px) {
    .inner-wrapper {
        width: 80%;
    }
}
@media all and (max-width: 1080px) {
    .wrapper {
        width: 80%;
    }
}

@media all and (max-width: 640px) {
    .inner-wrapper {
        width: 85%;
    }

    .wrapper {
        width: 90%;
    }
}

@media all and (max-width: 480px) {
    .inner-wrapper {
        width: 90%;
    }
    p {
        font-size: 14px;
    }
}

/* PAGINATION */

.pagination {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 30px;
    flex-wrap: wrap;
}

.pagination a {
    border: 1px solid rgb(15, 167, 111);
    width: 40px;
    height: 40px;
    display: flex;
    font-family: "DM_sans_medium";
    font-size: 15px;
    padding: 10px;
    border-radius: 50%;
    color: rgb(15, 167, 111);
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}

@media all and (max-width: 480px) {
    .pagination a {
        padding: 4px 12px;
    }
}

.pagination li {
    margin-right: 15px;
}

.pagination li.break a {
    display: inline-block;
}

.previous .pagination__link {
    background-color: unset;
    color: rgb(15, 167, 111);
    font-family: "DM_sans_medium";
}

.next .pagination__link {
    background-color: unset;
    color: rgb(15, 167, 111);
    font-family: "DM_sans_medium";
}

.pagination__link {
    font-weight: bold;
}

.pagination__link--active a {
    color: #fff;
    background: rgb(15, 167, 111);
}

.pagination__link--disabled a {
    cursor: not-allowed;
    display: none;
}

.active_link_classname {
    color: #fff;
    background: rgb(15, 167, 111);
}

/* PAGINATION ENDS*/

/* INPUT STYLES */
.input-wrapper {
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 8px 12px;
    width: 100%;
    height: 54px;
    position: relative;
    display: flex;
    align-items: center;
    border: 2px solid #f4f4f4;
}
.input-wrapper:focus-within {
    background-color: #ffff;
    border: 2px solid #366ee3;
    box-shadow: 0px 0px 0px 2px rgba(54, 110, 227, 0.19);
}
.input-value {
    width: 100%;
    font-weight: 400;
    font-size: 16px !important;
    color: #3e3e53;
    /* background-color: #F4F4F4; */
    border: none;
    outline: none;
}

.input-value::placeholder {
    color: #818181;
}
.input-label {
    font-weight: 400;
    font-size: 12px;
    color: #818181;
    margin-bottom: 2px;
    display: none;
}

/* select box */
.select-label {
    font-weight: 400;
    font-size: 16px;
    color: #818181;
    margin-bottom: 2px;
    display: block;
}
.select-value {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #3e3e53;
    background-color: #f4f4f4;
}

/* textarea */
.textarea-value {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: #3e3e53;
    background-color: #f4f4f4;
    border: none;
    outline: none;
    resize: none;
}
.textarea-value::placeholder {
    color: #818181;
}
.textarea-value:focus {
    background-color: #ffff;
}

/* Error message */
.error {
    color: #fe6666;
    font-size: 12px;
    display: block;
}

.count {
    color: #f4f4f4;
    font-size: 16px;
    margin-right: 5px;
    font-family: "DM_sans_medium";
}
.counter {
    color: #366ee3;
    font-size: 20px;
    font-family: "DM_sans_medium";
}
/* PAGINATION ENDS*/

/* popup video */

.popup-content {
    border: none !important;
    background: none !important;
}
.popup-overlay {
    z-index: 1120 !important;
}

body.modal-open {
    overflow: hidden !important;
}

/* Re-enable scrolling when modal is closed */
body:not(.modal-open) {
    overflow: auto;
}
