.calendar {
    font-family: Arial, sans-serif;
    /* margin: 0 auto; */
    /* max-width: 800px; */
    background-color: #fff;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    /* box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2); */
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 295px;
    /* background-color: #f7f7f7;
    border-bottom: 1px solid #ccc;
    border-radius: 5px 5px 0 0; */
    position: absolute;
    top: 100px;
}

.calendar-header button {
    background-color: #fff;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-family: "DM_sans_medium";
    outline: none;
}

.calendar-header button:hover {
    color: #2f2f2f;
}

.calendar-header h2 {
    font-size: 20px;
    /* font-weight: bold; */
    font-family: "DM_sans_medium";
    margin: 0;
}

.calendar-header img {
    cursor: pointer;
}
.calendar-body {
    border: 1px solid #e2e2e2;
    border-radius: 12px;
    overflow: hidden;
}

.row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: 0.5px solid #e2e2e2;
    border-bottom: none;
}

.cell,
.empty-cell {
    flex: 1;
    padding: 10px;
    text-align: left;
    border: 1px solid #e2e2e2;
    height: 120px;
    color: #3e3e53;
    /* width: fit-content; */
}
.header-cell {
    height: 50px;
    flex: 1;
    padding: 10px;
    text-align: center;
    border-right: 1px solid #e2e2e2;
    border-bottom: none;
}

.header-cell:first-child {
    border-top-left-radius: 9px;
}
.header-cell:last-child {
    border-top-right-radius: 9px;
}

.cell:hover {
    background-color: #f7f7f7;
}

.empty-cell {
    background-color: #f7f7f7;
}
