.fc .fc-toolbar-title {
    font-size: 20px;
    margin: 0;
    font-family: "DM_sans_medium";
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
    background-color: #e8effe;
    border: none;
    margin-right: 20px;
    border-radius: 8px;
    padding: 8px;
}
.fc-icon-chevron-left:before {
    content: "\e900";
    color: #000;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
    background-color: #e8effe;
    border: none;
    border-radius: 8px;
    border-radius: 8px;
}
.fc-icon-chevron-right:before {
    content: "\e901";
    color: #000;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0.75em;
    position: absolute;
    left: 170px;
}
.fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none;
}
.fc .fc-button:focus {
    box-shadow: none;
    outline: 0;
}
.fc .fc-daygrid-day.fc-day-today {
    background-color: #f4f8ff;
    border-top: 3px solid #296df1;
}
.fc .fc-daygrid-day-top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.fc-daygrid-dot-event:hover {
    background: none;
}
.fc .fc-daygrid-day-frame:hover {
    background: #f7f7f8;
}
.fc .fc-daygrid-day-frame {
    cursor: pointer;
    width: auto;
}
.fc-h-event {
    background-color: #ffffff00;
    border: none;
    display: block;
}
.fc .fc-daygrid-day-number {
    padding: 8px;
    z-index: 4;
}
.fc-dom-86 h2 {
    font-size: 16px;
}
